import {
  BoundingBoxIcon,
  Rocket01Icon,
  SolarSystemIcon,
} from "@hugeicons/react";
import React from "react";

const PlanIcons = ({ icon, size = 20, color = "#7CA1CB" }) => {
  const props = {
    className: "mr-2",
    size: size || 20,
    variant: "stroke",
    type: "rounded",
    color: color,
  };
  switch (icon) {
    case "hgi-rocket-01":
      return <Rocket01Icon {...props} />;
    case "hgi-solar-system":
      return <SolarSystemIcon {...props} />;
    case "hgi-bounding-box":
      return <BoundingBoxIcon {...props} />;
    default:
      return <Rocket01Icon {...props} />;
  }
};

export default PlanIcons;
