import React from 'react';
import SectionCard from '../OverviewTab/SectionCard';
import { InformationSquareIcon, News01Icon } from '@hugeicons/react';
import ArticleCard from './ArticleCard';
import { useOutletContext } from 'react-router-dom';
import NoData from '../NoData';
import NoGraphData from '../NoGraphData';

const NewsTab = () => {
	const { news } = useOutletContext();
	console.log(news);
	const socialMediaLst = [
		{
			label: 'LinkedIn',
			data:
				typeof news?.social_media?.linkedin_url != 'string'
					? news?.social_media?.linkedin_url?.join(', ')
					: news?.social_media?.linkedin_url,
		},
		{
			label: 'X',
			data:
				typeof news?.social_media?.twitter_url != 'string'
					? news?.social_media?.twitter_url?.join(', ')
					: news?.social_media?.twitter_url,
		},
		{
			label: 'Facebook',
			data:
				typeof news?.social_media?.facebook_url != 'string'
					? news?.social_media?.facebook_url?.join(', ')
					: news?.social_media?.facebook_url,
		},
		{
			label: 'Instagram',
			data:
				typeof news?.social_media?.instagram_url != 'string'
					? news?.social_media?.instagram_url?.join(', ')
					: news?.social_media?.instagram_url,
		},
		{
			label: 'YouTube',
			data:
				typeof news?.social_media?.youtube_url != 'string'
					? news?.social_media?.youtube_url?.join(', ')
					: news?.social_media?.youtube_url,
		},
		{
			label: 'GitHub',
			data:
				typeof news?.social_media?.github_url != 'string'
					? news?.social_media?.github_url?.join(', ')
					: news?.social_media?.github_url,
		},
		{
			label: 'TikTok',
			data:
				typeof news?.social_media?.tiktok_url != 'string'
					? news?.social_media?.tiktok_url?.join(', ')
					: news?.social_media?.tiktok_url,
		},
		{
			label: 'Pinterest',
			data:
				typeof news?.social_media?.pinterest_url != 'string'
					? news?.social_media?.pinterest_url?.join(', ')
					: news?.social_media?.pinterest_url,
		},
	];
	const followersLst = [
		{
			label: 'LinkedIn Followers',
			data: news?.followers?.followers_count_linkedin,
		},
		{
			label: 'X (Twitter) Followers',
			data: news?.followers?.followers_count_twitter,
		},
	];
	return news?.data ? (
		<div className='w-full h-full flex justify-between'>
			{/* Left column */}
			<div className='w-3/5 h-full flex flex-col gap-5'>
				<div className='w-full h-auto flex items-center gap-2'>
					<News01Icon
						size={24}
						color='#5092E0'
						variant='stroke'
						type='rounded'
					/>
					<span className='text-[--dark-text] text-4xl font-medium leading-relaxed'>
						{news?.news_articles?.length}
					</span>
					<span className='text-[--dark-text] text-xs font-normal'>
						Articles
					</span>
				</div>
				{/* Show only if data is present */}
				{news?.news_articles?.length > 0 ? (
					<div className='w-full h-auto flex flex-col gap-5'>
						{news?.news_articles?.map((info, x) => (
							<ArticleCard
								key={x}
								headline={info?.headline}
								date={info?.published_date}
								news={info?.summary}
								url={info?.article_url}
							/>
						))}
					</div>
				) : (
					<NoData/>
				)}
			</div>
			{/* RIght column */}
			{/* Show only if data is present */}
			{news?.social_media && news?.followers && (
				<div className='w-1/3 h-full flex flex-col gap-3'>
					{news?.social_media?.data && (
						<>
							<div className='w-full text-xs text-[--dark-text] flex gap-1.5'>
								<InformationSquareIcon
									size={14}
									color='#5092E0'
									variant='stroke'
									type='rounded'
								/>
								Social Media
							</div>
							{socialMediaLst.map((info) => (
								<SectionCard
									key={info.label}
									label={info.label}
									data={info.data}
								/>
							))}
						</>
					)}
					{news?.followers?.data && (
						<>
							<div className='w-full text-xs text-[--dark-text] flex gap-1.5'>
								<InformationSquareIcon
									size={14}
									color='#5092E0'
									variant='stroke'
									type='rounded'
								/>
								Followers
							</div>
							{followersLst.map((info) => (
								<SectionCard
									key={info.label}
									label={info.label}
									data={info.data}
								/>
							))}
						</>
					)}
				</div>
			)}
		</div>
	) : (
		<NoData/>
	);
};

export default NewsTab;
