import React from 'react';

const TechStackCardPDF = ({ tech, date }) => {
	console.log(tech, date);
	return (
		<div className='w-full h-fit px-2 pb-2 rounded border border-[#3d86dc] justify-center items-center inline-flex flex-col text-center'>
			<span className='text-[#3d86dc] h-7 text-xs font-normal tracking-tight text-nowrap text-ellipsis overflow-hidden w-full'>
				{tech}
			</span>
			<span className='text-[#7b8da2] h-4 text-[8px] font-normal tracking-tight'>
				Last Verified at: {date}
			</span>
		</div>
	);
};

export default TechStackCardPDF;
