import React, { useEffect, useState, useCallback } from "react";
import styles from "./index.module.css";
import Add from "../../assets/icons/add.svg";
import SearchIcon from "../../assets/icons/search.svg";
import debounce from "lodash/debounce";
import { validateCoreSignalLimit } from "../../services";
import { CircularProgress, Modal } from "@mui/material";
import UsageLimitModal from "../Common/UsageLimitModal";
import { useNavigate } from "react-router-dom";

const TrackHeader = ({
  setSearchCompany,
  filterCompany,
  setSortBy,
  sortBy,
}) => {
  const nav = useNavigate();
  const [openUsageModal, setOpenUsageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleNew = async () => {
    try {
      setLoading(true);
      const res = await validateCoreSignalLimit("intelligence_search");
      if (!res) {
        setOpenUsageModal(true);
        setLoading(false);
        return;
      }
      setLoading(false);
      nav("/targets/search-company");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = useCallback(
    debounce((value) => {
      const cleanedValue = value.trim();
      setSearchTerm(cleanedValue);
      filterCompany(cleanedValue);
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const searchPlaceholder = "Search Track Name";

  return (
    <div className={styles.frameParent}>
      <Modal
        styles={"width:300px"}
        open={openUsageModal}
        onClose={() => setOpenUsageModal(false)}
      >
        <UsageLimitModal closeModal={() => setOpenUsageModal(false)} />
      </Modal>
      <div className={styles.frameGroup}>
        <div className={styles.newestParentCont}>
          <select
            value={sortBy}
            onChange={(e) => {
              console.log("e", e.target.value);
              setSortBy(e.target.value);
            }}
            className={styles.newestParent}
          >
            <option value={0} className={styles.newest}>
              Newest
            </option>
            <option value={1} className={styles.newest}>
              Oldest
            </option>
          </select>
        </div>
        <div className={styles.search01Parent}>
          <img className={styles.search01Icon} alt="" src={SearchIcon} />
          <input
            type="text"
            placeholder={searchPlaceholder}
            className={styles.searchInput}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          />
        </div>
      </div>
      <div className={styles.frameContainer}>
        <button
          id="createTrack"
          onClick={handleNew}
          className={`${styles.add01Parent} hover:scale-105 cursor-pointer`}
        >
          {loading ? (
            <CircularProgress
              size={16}
              sx={{
                color: "white",
              }}
            />
          ) : (
            <img className={styles.add01Icon} alt="add" src={Add} />
          )}
          <span className={styles.newest}>New Company</span>
        </button>
      </div>
    </div>
  );
};

export default TrackHeader;
