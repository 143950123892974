import React from 'react';
import SectionCard from './SectionCard';
import {
	InformationSquareIcon,
	Link05Icon,
	Money01Icon,
	MoneySavingJarIcon,
	MoreIcon,
} from '@hugeicons/react';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import UpdatesSection from './Updates';
import { useOutletContext } from 'react-router-dom';
import NoData from '../NoData';
import NoGraphData from '../NoGraphData';

const OverviewTab = () => {
	const { overview } = useOutletContext();
	const { colorMode } = useTheme();
	const basicCompanyInfo = [
		{
			label: 'Company Legal Name',
			data: overview?.basic_info?.company_legal_name,
		},
		{ label: 'Country', data: overview?.basic_info?.country },
		{ label: 'Status', data: overview?.basic_info?.status },
		{ label: 'Company Size', data: overview?.basic_info?.company_size },
		{ label: 'Founded Year', data: overview?.basic_info?.founded_year },
		{ label: 'Industry', data: overview?.basic_info?.industry },
		{ label: 'Type', data: overview?.basic_info?.type },
		{ label: 'Employee Count', data: overview?.basic_info?.employee_count },
		{ label: 'Ownership Status', data: overview?.basic_info?.ownership_count },
		{
			label: 'Parent Company',
			data: overview?.basic_info?.parent_company?.parent_company_name,
		},
	];

	return overview?.data ? (
		<div className='w-full h-full flex justify-between'>
			{/* Left column */}
			<div className='w-3/5 h-full flex flex-col gap-5'>
				<SectionCard
					icon={
						<Link05Icon
							size={18}
							color={colorMode == 'dark' ? '#e1e1e1' : '#1e1e1e'}
							variant='stroke'
							type='rounded'
						/>
					}
					label='Main Website'
					data={overview?.main_website ?? 'No intelligence available'}
				/>
				<div className='w-full h-auto'>
					<div className='w-full h-auto flex justify-start gap-1.5 items-center'>
						<MoreIcon
							size={14}
							color='#5092E0'
							variant='stroke'
							type='rounded'
						/>
						<h3 className='text-[--dark-text] text-xs font-medium'>
							Description
						</h3>
					</div>
					<div className="w-full min-h-16 grow mt-1 p-3.5 rounded border border-[--secondary-border] text-[--secondary-text] text-[10px] font-medium font-['Inter']">
						{overview?.description ?? 'No intelligence available'}
					</div>
				</div>
				<SectionCard
					icon={
						<Money01Icon
							size={18}
							color={colorMode == 'dark' ? '#e1e1e1' : '#1e1e1e'}
							variant='stroke'
							type='rounded'
						/>
					}
					label='Annual Revenue Range (Most Recent Financial Year)'
					data={overview?.annual_revenue_range}
				/>
				<SectionCard
					icon={
						<MoneySavingJarIcon
							size={18}
							color={colorMode == 'dark' ? '#e1e1e1' : '#1e1e1e'}
							variant='stroke'
							type='rounded'
						/>
					}
					label='Annual Revenue (Most Recent Financial Year)'
					data={overview?.annual_revenue}
				/>
				{/* Show only if data is present */}
				{overview?.updates?.length > 0 && <UpdatesSection />}
			</div>
			{/* Right column */}
			{/* Show only if data is present */}
			{overview?.basic_info?.data && (
				<div className='w-1/3 h-full flex flex-col gap-3'>
					<div className='w-full text-xs text-[--dark-text] flex gap-1.5'>
						<InformationSquareIcon
							size={14}
							color='#5092E0'
							variant='stroke'
							type='rounded'
						/>
						Basic Info
					</div>
					{basicCompanyInfo.map((info) => (
						<SectionCard key={info.label} label={info.label} data={info.data} />
					))}
				</div>
			)}
		</div>
	) : (
		<NoData/>
	);
};

export default OverviewTab;
