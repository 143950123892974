import React from 'react';

const SectionCardPDF = ({ icon, label, data }) => {
	function estimateStringWidth(text, fontSize = 23, averageCharWidth = 0.5) {
		// fontSize: in pixels (e.g., 0.75rem = 12px)
		// averageCharWidth: estimated average width of a single character relative to fontSize

		// Calculate the total width
		const width = text.length * fontSize * averageCharWidth;
		return width; // Return width in pixels
	}

	const varW = estimateStringWidth(label);

	return !!data ? (
		<div className='w-full relative h-9 px-2.5 rounded border border-[--secondary-border] text-[--secondary-text] justify-start items-center gap-x-1.5 flex flex-row'>
			{icon && <p className='relative h-8 inline-flex items-center'>{icon}</p>}
			<div
				style={{
					width: `${varW}px`,
				}}
				className={` inline-block text-nowrap text-xs font-normal leading-9 -mt-4`}>
				{label}:
			</div>
			<p className='inline-block text-nowrap w-full text-xs font-medium leading-9 -mt-4 overflow-hidden text-ellipsis'>
				{data}
			</p>
		</div>
	) : null;
};

export default SectionCardPDF;
