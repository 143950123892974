import {
	InformationSquareIcon,
	Link05Icon,
	Money01Icon,
	MoneySavingJarIcon,
	MoreIcon,
	StarsIcon,
} from '@hugeicons/react';
import React from 'react';
import SectionCard from '../../Components/Target/OverviewTab/SectionCard';
import SectionCardPDF from '../../Components/PDF/SectionCardPDF';
import SharboLogo from '../../assets/images/sharbo-logo.svg';

const OverviewPage = ({ data }) => {
	const basicCompanyInfo = [
		{
			label: 'Company Legal Name',
			data: data?.overview?.basic_info?.company_legal_name,
		},
		{ label: 'Country', data: data?.overview?.basic_info?.country },
		{ label: 'Status', data: data?.overview?.basic_info?.status },
		{ label: 'Company Size', data: data?.overview?.basic_info?.company_size },
		{ label: 'Founded Year', data: data?.overview?.basic_info?.founded_year },
		{ label: 'Industry', data: data?.overview?.basic_info?.industry },
		{ label: 'Type', data: data?.overview?.basic_info?.type },
		{
			label: 'Employee Count',
			data: data?.overview?.basic_info?.employee_count,
		},
		{
			label: 'Ownership Status',
			data: data?.overview?.basic_info?.ownership_count,
		},
		{
			label: 'Parent Company',
			data: data?.overview?.basic_info?.parent_company?.parent_company_name,
		},
	];

	return (
		<div className='flex relative flex-col w-full h-[1056px]  justify-start items-center bg-black relative px-16 py-24'>
			<div className='w-full flex flex-row justify-between items-start gap-16'>
				<div className='text-white flex flex-col justify-start items-start gap-5 w-1/2'>
					<div className='w-full flex flex-row justify-start gap-10 items-center'>
						{data?.company_logo && (
							<img
								src={'data:image/png;base64,' + data?.company_logo}
								alt='Company Logo'
								className='w-20 h-20 rounded-lg object-contain'
							/>
						)}
						<h1 className='text-[60px] font-medium tracking-tight -mt-12'>
							{data?.company_name}
						</h1>
					</div>
					<div className='w-auto max-w-max px-2.5 py-2 rounded border border-[#616161] inline-flex items-center gap-3 text-xs font-medium mt-3'>
						<StarsIcon
							size={16}
							color='#5092E0'
							variant='stroke'
							type='rounded'
						/>
						<span className='text-xs font-light -mt-3'>Industry:</span>
						<span className='text-xs font-medium -mt-3'> {data?.industry}</span>
					</div>
					<div className='w-full flex h-6 items-center gap-2.5 text-xs  tracking-tight leading-6'>
						<span className='font-normal'>Data Freshness:</span>
						<span className='font-medium'>{data.data_freshness}</span>
					</div>
				</div>
				<div className='w-1/2'>
					{data?.overview?.basic_info?.data && (
						<div className='h-full flex flex-col gap-3 -mt-8'>
							<div className='w-full text-xs text-[--dark-text] flex gap-2'>
								<InformationSquareIcon
									size={14}
									color='#5092E0'
									variant='stroke'
									type='rounded'
								/>
								<span className='-mt-3'>Basic Info</span>
							</div>
							{basicCompanyInfo.map((info) => (
								<SectionCardPDF
									key={info.label}
									label={info.label}
									data={info.data}
								/>
							))}
						</div>
					)}
				</div>
			</div>
			<div className='w-full h-full flex flex-col gap-5 -mt-8'>
				<h3 className='text-white font-bold text-[40px]'>Overview</h3>
				<SectionCardPDF
					icon={
						<Link05Icon
							size={18}
							color={'#e1e1e1'}
							variant='stroke'
							type='rounded'
						/>
					}
					label='Main Website'
					data={data?.overview?.main_website ?? 'No intelligence available'}
				/>
				<div className='w-full h-auto'>
					<div className='w-full h-auto flex justify-start gap-1.5 items-center'>
						<MoreIcon
							size={14}
							color='#5092E0'
							variant='stroke'
							type='rounded'
						/>
						<h3 className='text-[--dark-text] text-xs font-medium -mt-3'>
							Description
						</h3>
					</div>
					<div className="w-full min-h-16 grow mt-2 px-3.5 pb-3.5 rounded border border-[--secondary-border] text-[--secondary-text] text-[10px] font-medium font-['Inter']">
						{data?.overview?.description ?? 'No intelligence available'}
					</div>
				</div>
				<SectionCardPDF
					icon={
						<Money01Icon
							size={18}
							color={'#e1e1e1'}
							variant='stroke'
							type='rounded'
						/>
					}
					label='Annual Revenue Range (Most Recent Financial Year)'
					data={data?.overview?.annual_revenue_range}
				/>
				<SectionCardPDF
					icon={
						<MoneySavingJarIcon
							size={18}
							color={'#e1e1e1'}
							variant='stroke'
							type='rounded'
						/>
					}
					label='Annual Revenue (Most Recent Financial Year)'
					data={data?.overview?.annual_revenue}
				/>
				{/* Show only if data is present */}
				{/* {data?.overview?.updates?.length > 0 && <UpdatesSection />} */}
			</div>
			<div className='text-white absolute bottom-0 right-10 flex flex-row justify-start items-center'>
				<div className='text-xs text-white -mt-3'>Powered By</div>
				<img src={SharboLogo} className='w-20' />
			</div>
		</div>
	);
};

export default OverviewPage;