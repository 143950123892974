import styles from "./index.module.css";
import TrackHeader from "../../Components/TrackHeader";
import TrackCard from "../../Components/TrackCard";
import { useState } from "react";
import ReactPaginate from "react-paginate";

const TargetList = ({ companies, setSearchCompany, sortBy, setSortBy }) => {
  const itemsPerPage = 6;
  const [filteredCompanies, setFilteredCompanies] = useState(companies);
  const [currentPage, setCurrentPage] = useState(0);

  const filterCompany = (searchTerm) => {
    if (searchTerm === "") {
      setFilteredCompanies(companies);
      return;
    }
    const filtered = companies.filter((company) =>
      company?.company_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  const pageCount = Math.ceil(filteredCompanies.length / itemsPerPage);
  const displayedData = filteredCompanies.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  console.log(
    displayedData,
    pageCount,
    currentPage,
    filteredCompanies.length > itemsPerPage
  );

  return (
    <>
      <div className="h-auto overflow-y-auto w-full flex flex-col justify-start items-center p-4 gap-5 flex-grow">
        <div className="w-full text-white">
          Saved Targets ({companies?.length})
        </div>
        <TrackHeader
          setSearchCompany={setSearchCompany}
          filterCompany={filterCompany}
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
        {filteredCompanies?.length > 0 ? (
          <>
            <div className="grid grid-cols-2 w-full justify-center items-center gap-6 px-5 flex-grow">
              {displayedData.map((item) => (
                <TrackCard companyData={item} />
              ))}
            </div>
          </>
        ) : (
          <div className="h-full w-full flex flex-col justify-center items-center p-4 gap-5">
            <h1 className="text-[--dark-text] text-base text-center font-medium">
              No Saved target found
            </h1>
          </div>
        )}
      </div>
      {filteredCompanies.length > itemsPerPage && (
        <div className="h-40 relative text-center px-4">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={({ selected }) => setCurrentPage(selected)}
            containerClassName={styles.pagination}
            activeClassName={styles.activePage}
            previousClassName={styles.pageItem}
            nextClassName={styles.pageItem}
            disabledClassName={styles.disabled}
            breakLinkClassName={styles.break}
          />
        </div>
      )}
    </>
  );
};

export default TargetList;
