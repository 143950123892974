import React, { useEffect, useRef, useState } from 'react';
import CoverPage from './CoverPage';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { Download05Icon } from '@hugeicons/react';
import { style } from '@mui/system';
import LoadingState from '../../Components/Target/LoadingState';
import OverviewPage from './OverviewPage';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { getCompanyData, getSavedCompany } from '../../services';
import TechStackPage from './TechStackPage';
import PricingPage from './PricingPage';
import WebTrafficPage from './WebTraffic';
import ReviewPage from './ReviewPage';
import RecentNewsPage from './RecentNews';
import WorkforcePage from './Workforce';
import GatheringIntelligence from '../../Components/Target/GatheringIntelligence';
import FundingPage from './Funding';

const PDFPages = ({}) => {
	const options = {
		// default is `save`
		method: 'save',
		// default is Resolution.MEDIUM = 3, which should be enough, higher values
		// increases the image quality but also the size of the PDF, so be careful
		// using values higher than 10 when having multiple pages generated, it
		// might cause the page to crash or hang.
		resolution: Resolution.LOW,
		page: {
			// margin is in MM, default is Margin.NONE = 0
			margin: Margin.NONE,
			// default is 'A4'
			format: 'letter',
			// default is 'portrait'
			orientation: 'portrait',
		},

		canvas: {
			// default is 'image/jpeg' for better size performance
			mimeType: 'image/png',
			qualityRatio: 1,
		},
		// Customize any value passed to the jsPDF instance and html2canvas
		// function. You probably will not need this and things can break,
		// so use with caution.
		overrides: {
			// see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
			pdf: {
				compress: true,
			},
			// see https://html2canvas.hertzen.com/configuration for more options
			canvas: {
				useCORS: true,
			},
		},
	};

	const saved = true;
	const path = useLocation()
		?.pathname.split('/')
		.filter((item) => item !== '');
	const companyId = path?.length < 3 ? path.pop() : path[path.length - 2];

	const targetRef = useRef();

	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getCompanyInfo();
	}, []);

	const savePDF = async () => {
		await generatePDF(targetRef, options);
		// window.close();
	};

	useEffect(() => {
		if (data && !loading) {
			setTimeout(() => {
				savePDF();
			}, 1000);
		}
	}, [data]);

	const getCompanyInfo = async () => {
		try {
			setLoading(true);
			if (saved) {
				const response = await getSavedCompany(companyId);
				setData(response);
			} else {
				const response = await getCompanyData(companyId);
				setData(response);
			}
			setLoading(false);
		} catch (error) {
			console.error('Error fetching company data:', error);
			setLoading(false);
			toast.error('Error fetching company data');
		}
	};

	return loading ? (
		<div className='flex w-screen h-screen overflow-y-auto flex-col justify-center items-center bg-black scrollbar-hide'>
			<LoadingState />
		</div>
	) : (
		<div className='flex w-screen h-screen overflow-y-auto flex-col justify-start items-center bg-black scrollbar-hide'>
			<div
				ref={targetRef}
				className='flex w-full h-fit flex-col max-w-[816px] justify-start items-center bg-black'>
				<CoverPage />
				{data?.overview?.data && <OverviewPage data={data} />}
				{data?.techstack?.data && <TechStackPage data={data} />}
				{data?.pricing?.data && <PricingPage data={data} />}
				{data?.website_traffic?.data && <WebTrafficPage data={data} />}
				{data?.product_review?.data && <ReviewPage data={data} />}
				{data?.news?.data && <RecentNewsPage data={data} />}
				{data?.funding?.data && <FundingPage data={data} />}
				{data?.workforce?.data && <WorkforcePage data={data} />}
			</div>
			{/* <div className='flex w-screen h-screen overflow-y-auto flex-col justify-center items-center bg-black scrollbar-hide fixed top-0 left-0'>
				<LoadingState />
			</div> */}
		</div>
	);
};

export default PDFPages;