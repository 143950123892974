import { ChartEvaluationIcon } from "@hugeicons/react";
import React from "react";
import { useOutletContext } from "react-router-dom";
import NoData from "../NoData";
import NoGraphData from "../NoGraphData";

const FundingTab = () => {
  const { funding } = useOutletContext();

  return funding?.data ? (
    <>
      <div className="w-full h-auto flex items-center gap-2">
        <ChartEvaluationIcon
          size={18}
          color="#5092E0"
          variant="stroke"
          type="rounded"
        />
        <span className="text-[--dark-text] text-xs font-normal">
          Total Funding Amount:
        </span>
        <span className="text-[--dark-text] text-4xl font-medium leading-relaxed">
          ${funding?.total_funding_amount}
        </span>
        <span className="text-[--secondary-text] text-xs font-normal">
          {funding?.total_funding_rounds} Funding Rounds
        </span>
      </div>
      {funding?.funding_rounds?.length > 0 ? (
        funding?.funding_rounds?.map((item, index) => (
          <div className="w-full rounded border border-[--tab-section-border] text-[--dark-text] mt-5">
            {/* Header section */}
            <div className="w-full h-auto px-4 py-2.5 flex items-center justify-between border-b border-[--tab-section-border] rounded-t bg-[--active-card-title-bg]">
              <span className=" text-xs font-medium">{item?.name}</span>
              <span className="h-auto px-4 rounded-2xl bg-text-light/10 justify-center items-center gap-0.5 inline-flex text-[10px] leading-8 font-medium tracking-tight">
                Announced date:{" "}
                {item?.announced_date ?? "No intelligence available"}
              </span>
            </div>
            {/* Card body */}
            <div className="w-full h-auto  min-h-12 px-4 py-5 text-[--secondary-text] text-[10px] font-medium flex gap-5 items-center justify-evenly">
              <div className="w-auto min-w-[25%] max-w-md min-h-16 flex flex-col items-start justify-center">
                <span>{item?.num_investors ?? 0} Investors</span>
                {item?.lead_investors?.length > 0 ? (
                  <span>Lead Investor: {item?.lead_investors?.join(", ")}</span>
                ) : (
                  "No intelligence available"
                )}
              </div>
              <div
                className="w-auto min-w-[25%] relative text-xs px-2.5 rounded leading-8 flex items-center justify-between gap-2.5 border text-[--secondary-text] 
                    border-[--secondary-border] font-normal"
              >
                Amount Raised:{" "}
                {item?.amount_raised
                  ? "$" + item?.amount_raised
                  : "No intelligence available"}
              </div>
            </div>
          </div>
        ))
      ) : (
        <NoData />
      )}
    </>
  ) : (
    <NoData />
  );
};

export default FundingTab;
