import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CurrentPlanAndUsage from "../../../../Components/Subscriptions/CurrentPlanAndUsage/Index";
import AllPlanFeatures from "../../../../Components/Subscriptions/AllPlanFeatures";
import Starter from "../../../../assets/icons/Subscriptions/rocket-starter.svg";
import Advanced from "../../../../assets/icons/Subscriptions/advanced.svg";
import Elevate from "../../../../assets/icons/Subscriptions/elevate.svg";
import Ultra from "../../../../assets/icons/Subscriptions/ultra.svg";
import "../SubscriptionPage/index.css";
import { getCurrentSubscriptions } from "../../../../services";
import { CircularProgress } from "@mui/material";

const SubscriptionBasicPlanPage = () => {
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const returnUrl = window.location.href;

        const result = await getCurrentSubscriptions(returnUrl);
        if (result.data) {
          setCurrentSubscription(result.data);
        }
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSubscription();
  }, []);

  const getIconByPlanName = (planName) => {
    switch (planName) {
      case "Starter":
        return Starter;
      case "Advanced":
        return Advanced;
      case "Elevate":
        return Elevate;
      case "Ultra":
        return Ultra;
      default:
        return Starter;
    }
  };

  const formatProgressItems = (features) => {
    return Object.entries(features).map(([label, [value, max]]) => ({
      label,
      value,
      max,
    }));
  };

  const handleButtonClick = (url) => {
    if (url) {
      window.location.href = url;
    }
  };

  const renderCurrentPlan = () => {
    if (!currentSubscription) return null;

    const {
      current_tier,
      current_tier_price,
      billing_cycle,
      next_payment_date,
      features,
      subscription_manage_plan_url,
      subscription_upgrade_plan_url,
      current_tier_icon,
    } = currentSubscription;

    return (
      <CurrentPlanAndUsage
        image={current_tier_icon}
        alt={current_tier}
        planName={`${current_tier} - ${current_tier_price}`}
        progressItems={formatProgressItems(features)}
        subscriptionButtonText={
          subscription_upgrade_plan_url ? "Upgrade Plan" : "Manage Plan"
        }
        isStarterPlan={!subscription_manage_plan_url}
        billingCycle={billing_cycle}
        nextPayment={next_payment_date}
        manageUrl={subscription_manage_plan_url}
        upgradeUrl={subscription_upgrade_plan_url}
      />
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {renderCurrentPlan()}
      <AllPlanFeatures />
    </div>
  );
};

export default SubscriptionBasicPlanPage;
