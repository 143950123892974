import React, { useEffect, useMemo, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TargetHeader from "../../Components/Target/HeaderSection";
import {
  Analytics01Icon,
  ChartBarLineIcon,
  ChartColumnIcon,
  MoneyExchange03Icon,
  NanoTechnologyIcon,
  NewsIcon,
  ProfileIcon,
  UserGroupIcon,
  WebDesign01Icon,
} from "@hugeicons/react";
import { getCompanyData, getSavedCompany } from "../../services";
import { toast } from "react-toastify";
import LoadingState from "../../Components/Target/LoadingState";
import { Modal } from "@mui/material";
import UsageLimitModal from "../../Components/Common/UsageLimitModal";
import GatheringIntelligence from "../../Components/Target/GatheringIntelligence";

const TargetCompany = () => {
  const nav = useNavigate();
  const [params] = useSearchParams();
  const saved = params.get("saved") == "true";
  const path = useLocation()
    ?.pathname.split("/")
    .filter((item) => item !== "");
  const companyId = params.get("id");
  const openTab = useMemo(() => {
    return path?.length < 3 ? "" : path.pop();
  }, [path]);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const tabs = [
    {
      showTab: data?.overview?.data ?? true,
      name: "Overview",
      path: "/target/" + data?.company_name,
      icon: <ProfileIcon size={12} color="currentColor" variant="stroke" />,
    },
    {
      showTab: data?.techstack?.data ?? true,
      name: "Tech Stack",
      path: "tech-stack",
      icon: (
        <NanoTechnologyIcon size={12} color="currentColor" variant="stroke" />
      ),
    },
    {
      showTab: data?.pricing?.data ?? true,
      name: "Product Pricing",
      path: "pricing",
      icon: <ChartColumnIcon size={12} color="currentColor" variant="stroke" />,
    },
    {
      showTab: data?.website_traffic?.data ?? true,
      name: "Website Traffic",
      path: "traffic",
      icon: <WebDesign01Icon size={12} color="currentColor" variant="stroke" />,
    },
    {
      showTab: data?.product_review?.data ?? true,
      name: "Product Review",
      path: "review",
      icon: <Analytics01Icon size={12} color="currentColor" variant="stroke" />,
    },
    {
      showTab: data?.news?.data ?? true,
      name: "News",
      path: "news",
      icon: <NewsIcon size={12} color="currentColor" variant="stroke" />,
    },
    {
      showTab: data?.funding?.data ?? true,
      name: "Funding",
      path: "funding",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={12}
          height={12}
          color="currentColor"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3C3.55229 3 4 3.44772 4 4V14C4 15.4425 4.00213 16.4237 4.10092 17.1586C4.19585 17.8646 4.36322 18.1916 4.58579 18.4142C4.80836 18.6368 5.13538 18.8042 5.84143 18.8991C6.57625 18.9979 7.55752 19 9 19H21C21.5523 19 22 19.4477 22 20C22 20.5523 21.5523 21 21 21H8.92946C7.57533 21 6.4587 21.0001 5.57494 20.8812C4.64711 20.7565 3.82768 20.4845 3.17158 19.8284C2.51547 19.1723 2.2435 18.3529 2.11875 17.4251C1.99994 16.5413 1.99997 15.4247 2 14.0706L2 4C2 3.44772 2.44772 3 3 3Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 16C6 15.4477 6.44772 15 7 15L16 15C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17L7 17C6.44772 17 6 16.5523 6 16Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 12C6 11.4477 6.44772 11 7 11L20 11C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13L7 13C6.44772 13 6 12.5523 6 12Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 8C6 7.44772 6.44772 7 7 7L13 7C13.5523 7 14 7.44772 14 8C14 8.55229 13.5523 9 13 9L7 9C6.44772 9 6 8.55228 6 8Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
    {
      showTab:
        (data?.acquisition?.data && data?.acquisition?.num_acquisitions > 0) ??
        true,
      name: "Acquisitions",
      path: "acquisitions",
      icon: (
        <MoneyExchange03Icon size={12} color="currentColor" variant="stroke" />
      ),
    },
    {
      showTab: data?.workforce?.data ?? true,
      name: "Workforce",
      path: "workforce",
      icon: <UserGroupIcon size={12} color="currentColor" variant="stroke" />,
    },
  ];

  useEffect(() => {
    getCompanyInfo();
  }, []);

  const getCompanyInfo = async () => {
    try {
      setLoading(true);

      if (saved) {
        const response = await getSavedCompany(companyId);
        setData(response);
      } else {
        const response = await getCompanyData(companyId);
        if (response?.detail) {
          setData({ lock: true });
          return;
        }
        setData(response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching company data:", error);
      setLoading(false);
      toast.error("Error fetching company data");
    }
  };

  const contextProps = {
    reloadData: getCompanyInfo,
    saveNewData: setData,
  };

  return loading ? (
    <LoadingState />
  ) : (
    <>
      <Modal
        styles={"width:300px"}
        open={data?.lock}
        onClose={() => nav("/", { replace: true })}
      >
        <UsageLimitModal closeModal={() => nav("/", { replace: true })} />
      </Modal>
      {/* 60px is the height of the navbar */}
      <div className="w-full h-[calc(100vh-60px)] py-4 px-7">
        {/* Target header height = 128px */}
        <TargetHeader data={data} setData={setData} />
        {/* Body height = 100vh - (navbar + y-padding + header + margin btw header and body) */}
        {/* Body height = 100vh - (60px + 32px + 128px + 20px) = 100vh - 240px */}
        <div className="w-full h-[calc(100vh-240px)]  rounded border border-[--tab-section-border] mt-5">
          {/* Tabs bar height = 60px */}
          <div className="relative min-w-full w-auto h-auto max-h-[60px] px-4 py-2.5 flex flex-nowrap gap-4 overflow-x-auto scrollbar-none border-b border-[--tab-section-border]">
            {tabs
              ?.filter((tab) => tab?.showTab == true)
              .map((tab) => (
                <Link
                  className="text-nowrap"
                  key={tab.path}
                  to={tab.path + useLocation().search}
                >
                  <button
                    className={`relative text-xs font-normal p-2.5 rounded flex items-center justify-between gap-2.5 border transition-all duration-500 ease-in-out text-[--primary-blue] ${
                      openTab == tab?.path 
                      ||
                      (openTab == "" && tab?.name == "Overview")
                        ? "border-[--primary-blue] bg-[#627b99]/20 font-semibold"
                        : "border-transparent hover:border-[--primary-blue] after:hidden hover:after:block"
                    } after:content-[""] after:absolute after:-bottom-[11px] after:left-0 after:w-full after:h-px after:bg-[--primary-blue] after:z-10`}
                  >
                    {tab.icon}
                    {tab.name}
                  </button>
                </Link>
              ))}
          </div>
          <div className="w-full h-[calc(100vh-300px)] p-6 overflow-y-auto">
            <Outlet
              context={{
                ...data,
                ...contextProps,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TargetCompany;
