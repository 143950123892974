import React from 'react';

const ArticleCardPDF = ({ headline, date, news, url }) => {
	return (
		<div className='w-full rounded border border-[--tab-section-border] text-[--dark-text]'>
			{/* Header section */}
			<div className='w-full h-auto px-4 py-2.5 flex items-center justify-between gap-5 border-b border-[--tab-section-border] rounded-t bg-[--active-card-title-bg]'>
				<span className=' text-xs font-medium'>{headline ?? 'Headline'}</span>
				<span className='h-auto px-4 rounded-2xl bg-text-light/10 justify-center items-center gap-0.5 text-nowrap inline-flex text-[10px] leading-8 font-medium tracking-tight'>
					Published on: {date ?? 'No intelligence available'}
				</span>
			</div>
			{/* Card body */}
			<div className='w-full h-auto  min-h-12 px-4 py-5 text-[--secondary-text] text-[10px] font-medium flex gap-5 items-center justify-between'>
				<div className='w-auto max-w-md min-h-16'>
					{news ?? 'No intelligence available'}
				</div>
			</div>
		</div>
	);
};

export default ArticleCardPDF;
