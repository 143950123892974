import styles from "./index.module.css";
import Stars from "../../assets/icons/stars.svg";
import Dot from "../../assets/icons/dot.svg";
import Pause from "../../assets/icons/pause.svg";
import Activity from "../../assets/icons/activity.svg";
import AccessBlocked from "../Common/AccessBlocked";
import { useTheme } from "../../Context/ThemContext/ThemeContext";
import { useNavigate } from "react-router-dom";

const TrackCard = ({ companyData }) => {
  const nav = useNavigate();
  const { colorMode } = useTheme();
  return (
    <div
      onClick={() => {
        if (companyData.lock) return;
        nav(
          `/target/${companyData.company_name}?id=${companyData?.company_id}&saved=true`,
          { state: { saved: true } }
        );
      }}
      className={`${styles.frameParent} ${
        colorMode === "dark" ? styles.darkShadow : styles.lightShadow
      } ${companyData.lock ? "cursor-auto pointer-events-none" : ""}`}
    >
      <AccessBlocked locked={companyData.lock} source="Target" />
      <div className={styles.frameWrapper}>
        <div className={styles.trackNameParent}>
          <div className={styles.trackName + "w-full flex gap-2.5"}>
            {companyData?.company_logo && (
              <img
                src={"data:image/png;base64," + companyData?.company_logo}
                alt="Company Logo"
                className="w-5 h-5 rounded-lg object-contain"
              />
            )}
            {companyData.company_name}
          </div>
          <div className={styles.vectorParent}>
            <img className={styles.vectorIcon} alt="" src={Dot} />
            <img className={styles.vectorIcon} alt="" src={Dot} />
            <img className={styles.vectorIcon} alt="" src={Dot} />
          </div>
        </div>
      </div>
      <div className={styles.frameGroup}>
        <div className={styles.frameContainer}>
          <div className={styles.starsParent}>
            <img className={styles.starsIcon} alt="" src={Stars} />
            <div className={styles.foundationNameWrapper}>
              <div className={styles.trackName}>{companyData.industry}</div>
            </div>
          </div>
        </div>
        <div className={styles.trackStatusParent}>
          <div className={styles.trackStatus}>Alert Status:</div>
          <div className={styles.activity01Parent}>
            <img
              className={styles.activity01Icon}
              alt=""
              src={companyData.alert_status ? Activity : Pause}
            />
            <div className={styles.active}>
              {companyData.alert_status ? "Active" : "Inactive"}
            </div>
          </div>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.lastUpdatedParent}>
            <div className={styles.trackStatus}>Data Freshness:</div>
            <div className={styles.active}>{companyData.data_freshness}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackCard;
