import React from "react";
import SectionCard from "../OverviewTab/SectionCard";
import {
  ListViewIcon,
  SaveMoneyDollarIcon,
  UserListIcon,
} from "@hugeicons/react";
import { useOutletContext } from "react-router-dom";
import NoData from "../NoData";

const WorkforceTab = () => {
  const { workforce } = useOutletContext();
  const additionalData = [
    {
      label: "Average score of employee reviews",
      data: workforce?.additional?.avg_score_employee_reviews,
    },
    {
      label: "Current CEO approval score",
      data: workforce?.additional?.current_ceo_approval_score,
    },
    {
      label: "Current senior management score",
      data: workforce?.additional?.current_senior_management_score,
    },
    {
      label: "Compensation and benefits rating",
      data: workforce?.additional?.compensation_benefits_rating,
    },
    {
      label: "Business outlook rating:",
      data: workforce?.additional?.business_outlook_rating,
    },
  ];

  return workforce?.data ? (
    <div className="w-full h-auto xl:h-full flex justify-between flex-col-reverse xl:flex-row gap-y-6">
      {/* Left column */}
      <div className="w-full xl:w-3/5 h-full flex flex-col gap-5">
        {workforce &&
        workforce?.employees_count_breakdown_by_department &&
        Object?.entries(workforce?.employees_count_breakdown_by_department)
          ?.length > 0 ? (
          <div className="w-full flex flex-col gap-1.5 flex-grow overflow-hidden min-h-[75%] rounded border border-[--tab-section-border]">
            <div className="w-full h-auto flex justify-start gap-1.5 items-center pl-6 pr-10 pt-5">
              <UserListIcon
                size={14}
                color="#5092E0"
                variant="stroke"
                type="rounded"
              />
              <h3 className="text-[--dark-text] text-xs font-medium">
                Number of Employees:
              </h3>
              <h2 className="text-[--dark-text] text-xl font-medium leading-relaxed">
                {workforce?.employees_count}
              </h2>
            </div>
            <div className="w-full flex-grow flex flex-col gap-2.5 py-5 pl-6 pr-10 overflow-y-scroll ">
              {workforce &&
                Object?.entries(
                  workforce?.employees_count_breakdown_by_department
                )?.map((item, index) => (
                  <div className="w-full flex justify-start items-center text-xs leading-6 text-[--dark-text] rounded border border-[--tab-section-border] py-[7px] divide-x divide-[--tab-section-border]">
                    <div className="w-1/3 px-5">{item[0]}</div>
                    <div className="w-2/3 px-5 text-sm leading-6">
                      {item[1]}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <NoData />
        )}
        {workforce?.active_job_postings?.data && (
          <div className="w-full h-auto">
            <div className="w-full h-auto flex justify-start gap-1.5 items-center">
              <ListViewIcon
                size={14}
                color="#5092E0"
                variant="stroke"
                type="rounded"
              />
              <h3 className="text-[--dark-text] text-xs font-medium">
                Active Job Postings:
              </h3>
              <h2 className="text-[--dark-text] text-xl font-medium leading-relaxed">
                {workforce?.active_job_postings?.count}
              </h2>
            </div>
            <div className="w-full min-h-[500px] mb-5 max-h-[50vh] mt-1 p-3.5 rounded border border-[--secondary-border] text-[--secondary-text] text-[10px] font-medium font-['Inter'] overflow-y-auto">
              {workforce?.active_job_postings?.job_postings?.length > 0 ? (
                <ul className="flex flex-col gap-0.5 list-disc pl-5">
                  {workforce?.active_job_postings?.job_postings?.map(
                    (item, index) => (
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
              ) : (
                "No intelligence available"
              )}
            </div>
          </div>
        )}
      </div>
      {/* Right column */}
      {workforce?.additional?.data && workforce?.base_salary?.data && (
        <div className="w-full xl:w-1/3 h-full flex flex-col gap-3">
          {workforce?.additional?.data && (
            <>
              <div className="w-full text-xs text-[--dark-text]">
                Additional Intelligence:
              </div>
              {additionalData.map((info) => (
                <SectionCard
                  key={info.label}
                  label={info.label}
                  data={info.data}
                />
              ))}
            </>
          )}
          {workforce?.base_salary?.data && (
            <div className="w-full max-h-[50%] min-h-[500px] mb-5 flex flex-col gap-1.5 xl:flex-grow overflow-hidden px-3 py-4 rounded border border-[--tab-section-border]">
              <div className="w-full h-auto flex justify-start gap-1.5 items-center">
                <SaveMoneyDollarIcon
                  size={14}
                  color="#5092E0"
                  variant="stroke"
                  type="rounded"
                />
                <h3 className="text-[--dark-text] text-xs font-medium">
                  Base Salary
                </h3>
              </div>
              <div className="w-full flex-grow flex flex-col gap-2.5 py-5 overflow-y-scroll ">
                <div className="w-full flex justify-start items-center text-[10px] text-[--dark-text]">
                  <div className="w-full px-5">Title</div>
                  <div className="w-1/4 px-5">Median Salary</div>
                  <div className="w-1/4 px-5">Pay Period</div>
                </div>
                {workforce?.base_salary?.base_salary_data?.map(
                  (item, index) => (
                    <div
                      key={"salary" + index}
                      className="w-full flex justify-start items-center text-xs leading-6 text-[--dark-text] rounded border border-[--tab-section-border] py-[7px] divide-x divide-[--tab-section-border]"
                    >
                      <div className="w-full px-5">{item.title}</div>
                      <div className="w-1/4 px-5 text-sm leading-6">
                        {item.salary_median}
                      </div>
                      <div className="w-1/4 px-5"> {item?.pay_period}</div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <NoData />
  );
};

export default WorkforceTab;
