import React from 'react';
import SharboLogo from '../../assets/images/sharbo-logo.svg';

const PricingPage = ({}) => {
	const [year, setYear] = useState(new Date().getFullYear());

	const monthlyChart = groupDataByYear(
		website_traffic?.total_website_visits_by_month
	);

	return (
		<div className='flex relative flex-col w-full h-[1056px]  justify-end items-start bg-black relative px-16 py-24'>
			<div className='text-white flex flex-col justify-start items-start gap-5'>
				<h3 className='text-white font-bold text-[40px]'>Web Traffic</h3>
				<div className='w-full h-auto flex flex-col gap-10'>
					<div className='w-full h-auto flex flex-col justify-center gap-2'>
						<p className='text-gray-500 text-xs font-normal'>
							Monthly Website Visits
						</p>
						<div className='w-full flex flex-row gap-10 items-center'>
							<p className='text-[--dark-text] text-2xl font-medium leading-relaxed'>
								{formatNumberWithSuffix(website_traffic?.monthly_visits)}
							</p>
							<p className='text-green-400 text-xs font-normal'>
								{website_traffic?.monthly_visits_change}%{' '}
								<span className='text-gray-500 font-light text-xs tracking-[1.6px]'>
									VS LAST MONTH
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='text-white absolute bottom-0 right-10 flex flex-row justify-start items-center'>
				<div className='text-xs text-white -mt-3'>Powered By</div>
				<img src={SharboLogo} className='w-20' />
			</div>
		</div>
	);
};

export default PricingPage;