import React, { useState, useEffect, useCallback, useContext } from "react";
import "./index.css";
import ProgressItem from "../ProgressItem";
import { getCurrentSubscriptions } from "../../../services";
import { Link } from "react-router-dom";
import { useTheme } from "../../../Context/ThemContext/ThemeContext";
import { SubscriptionContext } from "../../../Context/SubscriptionContext";
import PlanIcons from "../../Subscriptions/PlanIcons";

const PlanUsage = () => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { colorMode } = useTheme();
  const { subscriptionUpdatedData } = useContext(SubscriptionContext);
  const fetchData = useCallback(async () => {
    const returnUrl = window.location.href;
    try {
      const result = await getCurrentSubscriptions(returnUrl);
      if (result.message === "Current subscription found") {
        setSubscriptionData(result.data);
      }
    } catch (err) {
      setError(" ");
      console.error("Fetch error:", err);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchData();
    const handleCustomUpdate = () => {
      fetchData();
    };
    window.addEventListener("subscription-updated", handleCustomUpdate);
    window.addEventListener("card-deleted", handleCustomUpdate);
    window.addEventListener("plan-changed", handleCustomUpdate);
    window.addEventListener("subscription-deleted", handleCustomUpdate);
    window.addEventListener("active-round-created", handleCustomUpdate);

    return () => {
      window.removeEventListener("subscription-updated", handleCustomUpdate);
      window.removeEventListener("card-deleted", handleCustomUpdate);
      window.removeEventListener("plan-changed", handleCustomUpdate);
      window.removeEventListener("subscription-deleted", handleCustomUpdate);
      window.removeEventListener("active-round-created", handleCustomUpdate);
    };
  }, [fetchData, subscriptionUpdatedData]);

  if (!subscriptionData) return <div> </div>;

  const progressItems = Object.entries(subscriptionData.features).map(
    ([label, [value, max]]) => ({
      label,
      value,
      max,
    })
  );

  return (
    <Link to="/settings/subscription">
      <div className={`plan-usage-container ${colorMode}`}>
        <div className="plan-usage-header">
          <div className="plan-usage-title">Plan Usage</div>
          <div className="plan-usage-advanced">
            <PlanIcons icon={subscriptionData.current_tier_icon} />
            <div className="advanced-text">{subscriptionData.current_tier}</div>
          </div>
        </div>
        {progressItems.map((item, index) => (
          <ProgressItem
            key={index}
            label={item.label}
            value={item.value}
            max={item.max}
          />
        ))}
      </div>
    </Link>
  );
};

export default PlanUsage;
