import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import EmailCard from "./EmailCard";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { Button } from "@mui/material";
import {
  getAlertFrequencyMetaData,
  getNotification,
  postNotification,
} from "../../../../services";
import { toast } from "react-toastify";
import { OutpointContext } from "../../../../Context/OutpointsContext/Index";
import { SET_API_LOADING } from "../../../../Context/OutpointsContext/action";
import { ArrowDown01Icon } from "@hugeicons/react";
import { useTheme } from "../../../../Context/ThemContext/ThemeContext";

const EmailPage = () => {
  const {
    state,
    state: { apiLoading },
    dispatch,
  } = useContext(OutpointContext);

  const { colorMode } = useTheme();
  const [saveButton, setSaveButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discardLoader, setDiscardLoader] = useState(false);
  const [alertDropdown, setAlertDropdown] = useState([]);

  const [dataState, setDataState] = useState({
    alert_frequency: "1",
    intelligence_search_count: 0,
    track_job_completed: true,
  });

  // Fetch notification data
  const getNotificationData = async () => {
    setLoading(true);
    try {
      let response = await getAlertFrequencyMetaData();
      if (response?.data) setAlertDropdown(response.data);
      let res = await getNotification();
      if (res?.data) setDataState(res.data);
    } catch (err) {
      console?.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getNotificationData();
  }, []);

  const handleDropdownChange = (e) => {
    setDataState((prev) => ({ ...prev, alert_frequency: e.target.value }));
    setSaveButton(true);
  };

  const handleSave = async () => {
    dispatch({ type: SET_API_LOADING, payload: true });
    try {
      let res = await postNotification(dataState);
      if (res?.message) {
        toast.success(res.message);
        getNotificationData();
        setSaveButton(false);
      } else {
        toast.error(res?.detail || res.detail?.[0]?.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to save changes");
    }
    dispatch({ type: SET_API_LOADING, payload: false });
  };

  const handleDiscard = async () => {
    try {
      setDiscardLoader(true);
      let res = await getNotification();
      if (res?.data) {
        setDataState(res.data);
        setSaveButton(false);
        setDiscardLoader(false);
      }
    } catch (error) {
      console.log(error);
      setDiscardLoader(false);
      setSaveButton(false);
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            padding: "300px 0px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.trackingUpdatesFromRoundsWrapper}>
              <div className={styles.trackingUpdatesFrom}>
                Tracking Updates for All Targets (With Alerts Enabled)
              </div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.whenANewScheduledTrackJobParent}>
                <div
                  className={
                    styles.whenANew + " max-w-[440px] w-auto flex-shrink"
                  }
                >
                  Set Sharbo AI's frequency to refresh data and email reports
                  for company targets
                </div>
                <Select
                  size="small"
                  labelId="select-label"
                  id="select"
                  value={String(dataState?.alert_frequency)}
                  IconComponent={() => (
                    <ArrowDown01Icon
                      className="absolute w-6 h-6 right-0 -z-10"
                      style={{ marginRight: "6px" }}
                      size={30}
                      color={colorMode == "dark" ? "#e1e1e1" : "#1e1e1e"}
                    />
                  )}
                  sx={{
                    color: "var(--dark-text)",
                    fontSize: "12px",
                    fontWeight: "400",
                    border: "1px solid var(--secondary-border)",
                    height: "40px",
                    zIndex: "6",
                  }}
                  onChange={handleDropdownChange}
                >
                  {alertDropdown?.map((item) => (
                    <MenuItem
                      key={item.id}
                      sx={{ fontSize: "12px", fontWeight: "400" }}
                      value={String(item.id)}
                    >
                      {item?.frequency}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {/* <div className="w-full text-xs font-medium text-[--botton-span-text]">
                Next Refresh & Report:
              </div> */}
            </div>
            <div className={styles.frameChild} />
            <div className={styles.frameGroup}>
              <div className={styles.trackingUpdatesFromRoundsWrapper}>
                <div className={styles.trackingUpdatesFrom}>
                  Tracking Updates from Rounds (If you enabled Tracking function
                  for a Round)
                </div>
              </div>
              <div className={styles.frameContainer}>
                <EmailCard
                  title="When a new scheduled Track Job is completed"
                  lable="track_job_completed"
                  dataState={dataState}
                  setDataState={setDataState}
                  setSaveButton={setSaveButton}
                />
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              {saveButton && (
                <div className={styles.saveDiv}>
                  <div>
                    <Button
                      disabled={discardLoader}
                      onClick={handleDiscard}
                      variant="outlined"
                      sx={{ margin: "10px", textTransform: "none" }}
                      className="hover:scale-105"
                    >
                      {discardLoader && (
                        <CircularProgress
                          sx={{
                            color: "white",
                            height: "10px !important",
                            width: "10px !important",
                          }}
                        />
                      )}
                      Discard changes
                    </Button>
                  </div>
                  <div>
                    <Button
                      disabled={discardLoader}
                      onClick={handleSave}
                      variant="contained"
                      sx={{ margin: "10px", textTransform: "none" }}
                      className="hover:scale-105"
                      // disabled={apiLoading}
                    >
                      {apiLoading && (
                        <CircularProgress
                          sx={{
                            color: "white",
                            height: "10px !important",
                            width: "10px !important",
                          }}
                        />
                      )}
                      Save changes
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailPage;
