import React, { useEffect, useState } from "react";
import { getAllSavedTargets } from "../../services/index";
import TargetSearch from "./TargetSearch";
import TargetList from "./TargetList";
import { toast } from "react-toastify";
import LoadingState from "../../Components/Target/LoadingState";
import GatheringIntelligence from "../../Components/Target/GatheringIntelligence";

const Targets = () => {
  const [companies, setCompanies] = useState([]);
  const [sortBy, setSortBy] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchCompany, setSearchCompany] = useState(true);

  const getAllTargets = async () => {
    setLoading(true);
    try {
      const response = await getAllSavedTargets(sortBy);
      setCompanies(response);
      if (response.length > 0) setSearchCompany(false);
    } catch (err) {
      toast?.error("Error fetching targets");
      setCompanies([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTargets();
  }, [sortBy]);

  return (
    <div className="h-full w-full overflow-y-auto">
      {loading ? (
        <LoadingState />
      ) : !searchCompany ? (
        <TargetList
          setSearchCompany={setSearchCompany}
          companies={companies}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      ) : (
        <TargetSearch />
      )}
    </div>
  );
};

export default Targets;
