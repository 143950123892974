import React from "react";

const UpdateCard = ({ postedDate, reactions, description }) => {
  return (
    <div className="w-full rounded border border-[--tab-section-border] dark:text-white">
      {/* Header section */}
      <div className="w-full h-auto px-2 py-1.5 flex items-center justify-between border-b border-[--tab-section-border] rounded-t bg-[--active-card-title-bg]">
        <span className=" text-xs font-medium">Posted Date: {postedDate}</span>
        <span className="h-auto px-2 py-1 rounded-2xl bg-text-light/10 justify-center items-center gap-0.5 inline-flex text-[--dark-text] text-[10px] font-medium tracking-tight">
          {reactions} Reactions
        </span>
      </div>
      {/* Card body */}
      <div className="w-full h-auto break-words min-h-12 p-2 text-[--secondary-text] text-[10px] font-medium">
          {description}
        </div>
    </div>
  );
};

export default UpdateCard;
