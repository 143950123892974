import React, { useContext, useEffect, useState } from "react";
import { Select, MenuItem, Modal } from "@mui/material";
import TargetsSearchIcon from "../../assets/icons/SidePanel/targets-search.svg";
import TargetsShark from "../../assets/icons/SidePanel/targets-shark.svg";

import { ColorPattern } from "../../constant/Color";
import { useTheme } from "../../Context/ThemContext/ThemeContext";
import {
  BASE_URL,
  headers,
  validateCoreSignalLimit,
} from "../../services/index";
import { useNavigate } from "react-router-dom";
import LoadingState from "../../Components/Target/LoadingState";
import UsageLimitModal from "../../Components/Common/UsageLimitModal";
import { SubscriptionContext } from "../../Context/SubscriptionContext";
import {
  Add01Icon,
  ArrowDown01Icon,
  CircleArrowDown01Icon,
} from "@hugeicons/react";
import { toast } from "react-toastify";
import GatheringIntelligence from "../../Components/Target/GatheringIntelligence";
import { set } from "react-hook-form";

const TargetSearch = () => {
  const { colorMode } = useTheme();
  const { fetchSubscriptionData } = useContext(SubscriptionContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [companies, setCompanies] = useState([]);
  const [searchType, setSearchType] = useState("name");
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSearchList, setShowSearchList] = useState(false);
  const [loadmore, setLoadmore] = useState(0);
  const [openUsageModal, setOpenUsageModal] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    if (loadmore !== 0) {
      handleSearch();
    }
  }, [loadmore]);

  const handleSearch = async (loadValue) => {
    if (!searchQuery.trim()) return;
    if (
      searchType === "website" &&
      searchQuery.includes("https://www.") &&
      searchQuery.length === 12
    )
      return;
    setShowSearchList(true);
    setLoading(true);

    try {
      const res = await validateCoreSignalLimit("intelligence_search");
      if (!res) {
        setOpenUsageModal(true);
        setLoading(false);
        setShowSearchList(false);
        return;
      }
      const response = await fetch(`${BASE_URL}/coresignal/search`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          name: searchType === "name" ? searchQuery : null,
          website: searchType === "website" ? searchQuery : null,
          loadmore: loadValue ?? loadmore,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch companies");
      }

      const data = await response.json();
      if (data.length === 0) {
        toast.error("No results found");
        setLoading(false);
        return;
      }
      if (data.filter((item) => item.company_id !== null)?.length <= 0) {
        toast.error(
          "We're sorry, the intelligence search function is temporarily unavailable due to increased traffic. Please check back again soon."
        );
      }
      // If it's the first loadmore, replace the list. Otherwise, append.
      setCompanies(data);
    } catch (err) {
      toast.error(err.message || "An unknown error occurred");
      setCompanies([]);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    if (loadmore === 2) {
      return;
    } else {
      setLoadmore((prevState) => prevState + 1);
    }
  };

  return (
    <div className="w-full h-[calc(100vh-60px)] py-4 px-7">
      <Modal
        styles={"width:300px"}
        open={openUsageModal}
        onClose={() => setOpenUsageModal(false)}
      >
        <UsageLimitModal closeModal={() => setOpenUsageModal(false)} />
      </Modal>
      <div className="w-full h-full flex flex-col justify-center items-center gap-7">
        <img src={TargetsSearchIcon} alt="Targets Search" className="mb-4" />
        <h1 className="text-base font-semibold text-center text-[#7B8DA2]">
          Explore Company Product Intelligence & Save as Targets
        </h1>
        <div className="w-11/12 xl:w-4/5 max-w-[800px] flex flex-row justify-center items-center gap-5 relative">
          <Select
            value={searchType}
            onChange={(e) => {
              setSearchType(e.target.value);
              setSearchQuery("https://www.");
            }}
            IconComponent={() => (
              <ArrowDown01Icon
                className="absolute w-6 h-6 right-0"
                style={{ marginRight: "16px", zIndex: -1, height: "auto" }}
                size={60}
                color={colorMode == "dark" ? "#e1e1e1" : "#1e1e1e"}
              />
            )}
            sx={{
              width: "150px",
              height: "40px",
              fontSize: "12px",
              lineHeight: "36px",
              background: "transparent",
              zIndex: 1,
              color: "#D0D5E6",
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: ColorPattern[colorMode].borderColor,
                },
              "& .MuiSelect-nativeInput": {
                zIndex: 5,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: ColorPattern[colorMode].borderColor,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${ColorPattern[colorMode].borderColor}`,
              },
              "& .MuiSelect-icon": {
                color: ColorPattern[colorMode].textColor,
              },
            }}
          >
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="website">Website</MenuItem>
          </Select>
          <div className="relative w-full">
            <input
              type="text"
              placeholder={`Search ${
                searchType === "name" ? "Company Name" : "Website"
              }`}
              value={searchQuery}
              onChange={(e) => {
                setShowSearchList(false);
                setCompanies([]);
                setSearchQuery(e.target.value);
              }}
              style={{
                borderColor: ColorPattern[colorMode].borderColor,
              }}
              className="relative w-full h-10 bg-transparent border border-gray-200 text-xs text-gray-300 leading-10 rounded-md pl-9 pr-[180px] py-1 placeholder-gray-500 focus:outline-none focus:border-blue-500"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              color={colorMode == "dark" ? "#e1e1e1" : "#1e1e1e"}
              fill={"none"}
              className="absolute z-10 left-2 top-2"
            >
              <path
                d="M16.0001 16.5L20 20.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 11.5C18 15.366 14.866 18.5 11 18.5C7.13401 18.5 4 15.366 4 11.5C4 7.63404 7.13401 4.50003 11 4.50003"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.5 3.50003L15.7579 4.19706C16.0961 5.11105 16.2652 5.56805 16.5986 5.90142C16.932 6.2348 17.389 6.4039 18.303 6.74211L19 7.00003L18.303 7.25795C17.389 7.59616 16.932 7.76527 16.5986 8.09864C16.2652 8.43201 16.0961 8.88901 15.7579 9.803L15.5 10.5L15.2421 9.803C14.9039 8.88901 14.7348 8.43201 14.4014 8.09864C14.068 7.76527 13.611 7.59616 12.697 7.25795L12 7.00003L12.697 6.74211C13.611 6.4039 14.068 6.2348 14.4014 5.90142C14.7348 5.56805 14.9039 5.11105 15.2421 4.19706L15.5 3.50003Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <button
              onClick={() => {
                setLoadmore(0);
                handleSearch(0);
              }}
              className="absolute z-10 right-4 top-1.5 w-auto h-7 rounded px-2.5 py-1 border border-[--secondary-border] hover:border-[#5092E0] hover:text-[#5092E0] text-[--secondary-text] text-xs font-medium leading-8 flex items-center justify-center gap-1.5"
            >
              Confirm & search
              <CircleArrowDown01Icon size={16} color="#5092E0" />
            </button>
          </div>
        </div>
        {showSearchList ? (
          <div
            style={{
              borderColor: ColorPattern[colorMode].borderColor,
            }}
            className="-mt-6 relative p-4 border border-gray-200 space-y-2 rounded min-h-80 max-h-80 w-[800px] overflow-y-auto flex flex-col gap-4 justify-between"
          >
            {loading ? (
              <div className="w-full h-60">
                <LoadingState />
              </div>
            ) : companies.length > 0 ? (
              <div className="w-full flex flex-col justify-start">
                {companies.map((company) => (
                  <div
                    key={company?.company_id}
                    className={`flex items-center p-2 mb-2 rounded-md border ${
                      selectedOption === company ? "ring ring-blue-500" : ""
                    }`}
                    style={{
                      borderColor: ColorPattern[colorMode].borderColor,
                    }}
                    onClick={() =>
                      selectedOption != company
                        ? setSelectedOption(company)
                        : setSelectedOption(null)
                    }
                  >
                    <input
                      type="radio"
                      id={`option-${company?.company_id}`}
                      name="company"
                      className="text-blue-500 focus:ring-blue-500 fill-black"
                      checked={selectedOption === company}
                      readOnly
                    />
                    <label
                      htmlFor={`option-${company?.company_id}`}
                      className="ml-2"
                    >
                      <p className="text-sm text-white font-medium">
                        {company?.company_name}
                      </p>
                      {company?.company_website && (
                        <p className="text-xs text-gray-400">
                          {company?.company_website}
                        </p>
                      )}
                    </label>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-400 text-sm text-center my-20">
                No results found
              </p>
            )}

            {!loading && companies?.length > 0 && (
              <div className="sticky py-2 bg-[--light-bg] bottom-0 left-0 right-0 flex flex-row w-full justify-between mt-4">
                {loadmore < 1 && (
                  <button
                    disabled={loading || loadmore >= 1}
                    onClick={handleLoadMore}
                    className="w-auto h-8 rounded px-2.5 border border-[--secondary-border] hover:border-[#5092E0] text-gray-100 text-xs font-medium leading-8 flex items-center justify-center gap-1"
                  >
                    <Add01Icon size={16} color="#5092E0" />
                    Load More
                  </button>
                )}
                <button
                  onClick={() => {
                    fetchSubscriptionData();
                    nav(
                      `/target/${selectedOption?.company_name}?id=${selectedOption?.company_id}&saved=false`,
                      {
                        state: { saved: false },
                      }
                    );
                  }}
                  disabled={!selectedOption}
                  className="w-auto h-8 justify-self-end rounded px-2.5 bg-[--primary-blue] text-white text-xs font-medium leading-8 flex items-center justify-center gap-1 disabled:opacity-50"
                >
                  View Intelligence
                </button>
              </div>
            )}
          </div>
        ) : (
          <img src={TargetsShark} alt="Targets Search" className="mt-20" />
        )}
      </div>
    </div>
  );
};

export default TargetSearch;
