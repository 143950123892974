import PlanIcons from "../PlanIcons";
import "../PlansComponent/index.css";

const PlansComponent = ({
  Free,
  Starter,
  image,
  alt,
  features,
  customButton,
  onCustomButtonClick,
}) => {
  return (
    <div className="starterDiv">
      <div className="starter-align">
        {/* <i className={"hgi-stroke " + image} /> */}
        <PlanIcons icon={image} size={16} />
        <strong className="starter" style={{ marginRight: "8px" }}>
          {Starter}
        </strong>
        <h4 className="free">{Free}</h4>
      </div>
      <div className="paraContent">
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </div>
      {customButton && (
        <div className="buttonContainer" style={{ margin: "12px 0" }}>
          <button
            className="customButton hover:scale-105"
            onClick={onCustomButtonClick}
            style={{
              display: "block",
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {customButton}
          </button>
        </div>
      )}
    </div>
  );
};

export default PlansComponent;