import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../BillingInfo/index.css';

const BillingInfo = ({ billingCycle, nextPayment, manageUrl, upgradeUrl }) => {
    const navigate = useNavigate();

    const handleNavigation = async (url) => {
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.success) {
                navigate('/settings?tab=subscription');
            } else {
                console.error('Operation failed:', data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="billing-info-container">
            <div className="billing-details">
                <span className='borderBetween'>Billing Cycle: <strong>{billingCycle}</strong> </span>
                <span className="nextPayment">
                    Next Payment:
                    <strong className="paymentCard">{nextPayment}</strong>
                </span>
            </div>
            <div className="billing-actions">
                {/* <button onClick={() => handleNavigation(manageUrl)} className="manage-plan-btn">Manage Plan & Billing</button>
                {upgradeUrl && <button onClick={() => handleNavigation(upgradeUrl)} className="upgrade-plan-btn">Upgrade Plan</button>} */}
                <div className="billing-actions">
                    {manageUrl && <a id='managePlanBtn' href={manageUrl} className="manage-plan-btn hover:scale-105 h-10 leading-10 py-0 cursor-pointer">Manage Plan & Billing</a>}
                    {upgradeUrl && <a id='upgradeBtn' href={upgradeUrl} className="upgrade-plan-btn hover:scale-105 h-10 leading-10 cursor-pointer" >Upgrade Plan</a>}
                </div>
            </div>
        </div>
    );
};

export default BillingInfo;