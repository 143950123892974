import { TvSmartIcon } from "@hugeicons/react";
import React from "react";
import UpdateCard from "./UpdateCard";
import { useOutletContext } from "react-router-dom";

const UpdatesSection = () => {
  const { overview } = useOutletContext();
 console.log(overview)
  return (
    <div className="w-full flex flex-col gap-1.5 flex-grow overflow-hidden min-h-[50vh] ">
      <div className="w-full flex justify-start gap-1.5 items-center">
        <TvSmartIcon
          size={14}
          color="#5092E0"
          variant="stroke"
          type="rounded"
        />
        <h3 className="text-[--dark-text] text-xs font-medium">Updates</h3>
        <h4 className="text-[#7b8da2] text-[10px] font-medium">
          (on LinkedIn)
        </h4>
      </div>
      <div className="w-full mb-5  pb-5 flex-grow rounded border border-[--tab-section-border] flex flex-col gap-1 p-4 mt-1 overflow-y-scroll overflow-x-hidden">
        {overview?.updates.map((item, index) => (
          <UpdateCard
            key={index}
            reactions={item.reactions_count}
            postedDate={item.date}
            description={item.description}
          />
        ))}
      </div>
    </div>
  );
};

export default UpdatesSection;
