import React from 'react';
import NoData from '../../Components/Target/NoData';
import ReviewBarChart from '../../Components/TargetCharts/ReviewBarCharts';
import ReviewDistChart from '../../Components/TargetCharts/ReviewDistCharts';
import { InformationSquareIcon } from '@hugeicons/react';
import SectionCardPDF from '../../Components/PDF/SectionCardPDF';
import SharboLogo from '../../assets/images/sharbo-logo.svg';

const ReviewPage = ({ data }) => {
	function groupDataByYear(data) {
		return data?.reduce((acc, item) => {
			const { year, ...rest } = item; // Extract year and other data
			if (!acc[year]) {
				acc[year] = []; // Initialize an array for the year if not exists
			}
			acc[year].push(rest); // Push the rest of the data into the corresponding year
			return acc;
		}, {});
	}

	const year = '2024';

	const graphData = groupDataByYear(
		data?.product_review?.product_reviews_score_by_month
	);

	console.log(graphData);

	const reviewData = [
		{
			label: 'Online Product Reviews',
			data: data?.product_review?.online_review,
		},
		{
			label: 'Sources',
			data: data?.product_review?.sources,
		},
	];

	return (
		<div className='flex relative flex-col w-full h-[1056px]  justify-start items-center bg-black relative px-16 py-24'>
			<div className='text-white flex flex-col justify-start items-start gap-3 w-full'>
				<h3 className='text-white font-bold text-[40px]'>Product Review</h3>
				<div className='w-full flex flex-col justify-start items-start gap-5'>
					<div classNßame='w-full h-auto flex flex-col justify-center gap-2 ml-16'>
						<p className='text-gray-500 text-xs font-normal'>
							Average Score of Product Reviews:
						</p>
						<div className='w-full flex flex-row gap-10 items-center'>
							<p className='text-[--dark-text] text-2xl font-medium leading-relaxed'>
								{data?.product_review.avg_score?.toFixed(2)}{' '}
								<span className='text-[--dark-text] font-light text-lg'>
									/ 5.0
								</span>
							</p>
							<p className='text-green-400 text-xs font-normal'>
								{data?.product_review.score_change?.current}%{' '}
								<span className='text-gray-500 font-light text-xs tracking-[1.6px]'>
									VS LAST MONTH
								</span>
							</p>
						</div>
					</div>
					{graphData[year]?.length > 0 && (
						<div
							id='reviewMonthlyChart'
							className='w-full min-h-[350px] -ml-12'>
							<ReviewBarChart data={graphData} year={year} />
						</div>
					)}
				</div>
				<div className='w-full flex flex-row justify-between gap-16'>
					<div className='w-1/2'>
						{data?.product_review.product_reviews_score_distribution &&
							Object?.entries(
								data?.product_review?.product_reviews_score_distribution
							)?.length > 0 && (
								<div className='text-white rounded-lg shadow-lg w-full max-w-4xl'>
									<p className='text-gray-400 text-xs'>
										Total -{' '}
										{data?.product_review.product_reviews_distribution_count}
									</p>
									<h3 className='text-sm font-semibold mb-2'>
										Review Score Distribution
									</h3>
									<ReviewDistChart
										data={
											data?.product_review.product_reviews_score_distribution
										}
									/>
								</div>
							)}
					</div>
					<div className='w-1/2'>
						<div className='w-full text-xs text-[--dark-text] flex mb-3 gap-2'>
							<InformationSquareIcon
								size={14}
								color='#5092E0'
								variant='stroke'
								type='rounded'
							/>
							<span className='-mt-2'>Additional Intelligence</span>
						</div>
						{reviewData.map((info) => (
							<SectionCardPDF
								key={info.label}
								label={info.label}
								data={info.data}
							/>
						))}
					</div>
				</div>
			</div>
			<div className='text-white absolute bottom-0 right-10 flex flex-row justify-start items-center'>
				<div className='text-xs text-white -mt-3'>Powered By</div>
				<img src={SharboLogo} className='w-20' />
			</div>
		</div>
	);
};

export default ReviewPage;